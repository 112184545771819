<template>
  <header class="header">
    <div class="top-bar">
      <div class="container">
        <address class="contacts">
          <div class="contact-item">
            <i class="mdi mdi-phone" aria-hidden="true"></i>
            <a href="#" type="tel">(512) 555-1212</a>
          </div>
          <div class="contact-item">
            <i class="mdi mdi-map-marker" aria-hidden="true"></i>
            <a href="#">123 Main Street, Austin, TX 78738</a>
          </div>
        </address>
        <div class="socials">
          <ul class="socials-list">
            <li v-for="(social, i) in socials" :key="i">
              <i :class="['mdi', `mdi-${social}`]" aria-hidden="true"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nav-bar">
      <div class="container">
        <router-link :to="{ name: 'Home' }">
          <img src="/img/logo.svg" class="logo" alt="" />
        </router-link>
        <nav>
          <ul class="nav-list">
            <li v-for="(item, k) in menuList" :key="k">
              <router-link :to="{ name: item.name }">{{
                item.text
              }}</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      socials: ["instagram", "facebook", "linkedin", "twitter"],
      menuList: [
        { text: "About the hospital", name: "Home" },
        { text: "Information for patients", name: "Home" },
        { text: "Find a provider", name: "FindProvider" },
        { text: "Doctor's appointment", name: "Home" },
        { text: "Contact", name: "Home" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.header {
  .container {
    @include container;
  }

  .mdi {
    font-size: 20px;
  }

  .top-bar {
    padding: 22px 0;
    color: #fff;
    background-color: var(--primary-color);

    .container {
      display: flex;
      justify-content: space-between;

      @include breakpoint(xsmall) {
        gap: 20px;
        flex-wrap: wrap;
      }
    }

    .contacts {
      display: flex;
      gap: 65px;
      font-style: normal;

      @include breakpoint(xsmall) {
        gap: 20px;
      }

      .contact-item {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    .socials-list {
      display: flex;
      gap: 16px;
    }
  }

  .nav-bar {
    padding: 16px 0;
    background-color: var(--secondary-color);

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      @include breakpoint(xsmall) {
        display: none;
      }
    }

    .nav-list {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      color: var(--primary-color);
      font-weight: var(--font-weight-bolder);
      font-size: 20px;
    }
  }
}
</style>
