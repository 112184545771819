import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import FindProviderPage from "@/views/FindProviderPage.vue";

Vue.use(VueRouter);

Vue.config.productionTip = false;

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/HomePage.vue"),
      meta: { title: "Austin General Hospital" },
    },
    {
      path: "/find-a-provider",
      name: "FindProvider",
      component: FindProviderPage,
      meta: { title: "Find a provider - Austin General Hospital" },
    },
    {
      path: "*",
      component: () => import("@/views/HomePage.vue"),
      meta: { title: "Austin General Hospital" },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }

    return { x: 0, y: 0 };
  },
});

router.afterEach((to, _) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title || "PatientGenie";
  });
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
